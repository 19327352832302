<template>
    <div>
        <!-- 主体 -->
        <LiefengContent>
            <template v-slot:title>{{ "微信群阅读情况" }}</template>
            <!-- 头部搜索层 -->
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search"></Form>
            </template>
            <!-- 分页层 -->
            <template v-slot:contentArea>
                <!-- 分页table -->
                <LiefengTable
                    :talbeColumns="pageForm.talbeColumns"
                    :tableData="pageForm.tableData"
                    :loading="pageForm.loading"
                    :fixTable="true"
                    :curPage="pageForm.page"
                    :total="pageForm.total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageForm.pageSize"
                    @hadlePageSize="changeTableSize"
                    :noText="'您还没管理微信群，进入随约移动办公添加微信群吧'"
                ></LiefengTable>
            </template>
            <!-- 新增modal -->
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"

export default {
    components: { LiefengContent, LiefengTable },
    data() {
        return {
            //分页参数
            pageForm: {
                talbeColumns: [
                    {
                        title: "微信群名称",
                        key: "groupName",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "群成员数",
                        key: "memberNum",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "阅读次数",
                        key: "uv",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "阅读人数",
                        key: "uvUser",
                        minWidth: 200,
                        align: "center",
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: { color: "blue", cursor: "pointer" },
                                    on: {
                                        click: () => {
                                            this.$core.openLayerFrame({
                                                type: 2,
                                                title: `阅读详情`,
                                                content: `/page#/wechatgroupdetail?id=${this.$route.query.id}`,
                                                area: ["100%", "100%"],
                                            })
                                        },
                                    },
                                },
                                params.row.uvUser
                            )
                        },
                    },
                    {
                        title: "阅读率（阅读人数/群成员数）",
                        key: "uvScale",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "占比（本群阅读次数/全部群阅读次数）",
                        key: "viewScale",
                        minWidth: 200,
                        align: "center",
                    },
                ],
                tableData: [],
                loading: false,
                page: 1,
                pageSize: 20,
                total: 0,
                currentPage: 0,
            },
        }
    },
    //create函数,刚加载页面时调用
    async created() {
        this.selectPage()
    },
    methods: {
        /*----- 分页事件部分 -------*/

        //分页事件改变
        changeTableSize(val) {
            this.pageForm.page = val.page
            this.pageForm.pageSize = val.pageSize
            this.selectPage()
        },
        // 方法部分
        /*----- 接口部分 -------*/
        async selectPage() {
            this.pageForm.loading = true
            await this.$get("/info/api/pc/information/v2/selectInfoWeixinGroupView", {
                informationId: this.$route.query.id,
            }).then(res => {
                this.pageForm.tableData = []
                this.pageForm.loading = false
                if (res.code == 200) {
                    this.pageForm.tableData = res.dataList
                    this.pageForm.total = res.maxCount
                    this.pageForm.currentPage = res.currentPage
                } else {
                    this.$Message.error({
                        content: "获取列表失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
</style>